<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Home</h1>
				<p>API Status: {{ data.status }}</p>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
import UserService from '../services/user.service';

export default {
	name: 'AppHome',
	data() {
		return {
			data: ''
		};
	},
	created() {
		UserService.getStatus().then(
			(response) => {
				this.data = response.data;
			},
			(error) => {
				this.data = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			}
		);
	},
};
</script>
